import React, { createContext, useReducer } from 'react';
import jwt_decode from 'jwt-decode';

const initialState = {
    token: localStorage.getItem('token')
        ? { isLoggedIn: true, ...jwt_decode(localStorage.getItem('token')) }
        : { isLoggedIn: false },
    customString: ''
};

export const UserContext = createContext(initialState);

export function UserStore({ children }) {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'LOGIN':
                localStorage.setItem('token', action.payload);
                return {
                    ...state,
                    token: {
                        isLoggedIn: true,
                        ...jwt_decode(localStorage.getItem('token')),
                    },
                };

            case 'LOGOUT':
                localStorage.removeItem('token');
                return { ...state, token: { isLoggedIn: false } };

            case 'SET_STRING': 
                return { ...state, customString: action.payload };

            default:
                return state;
        }
    }, initialState);

    return (
        <UserContext.Provider value={[state, dispatch]}>
            {children}
        </UserContext.Provider>
    );
}
