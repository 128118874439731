import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Tab, Tabs } from '@blueprintjs/core';
import { InputGroup, Card, Button } from '@blueprintjs/core';

import { SectionTab } from 'polotno/side-panel';
import { getKey } from 'polotno/utils/validate-key';
import { getImageSize } from 'polotno/utils/image';
import FaBrain from '@meronex/icons/fa/FaBrain';
import { t } from 'polotno/utils/l10n';
import { useParams } from 'react-router-dom';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { useCredits } from '../credits';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { getCrop } from 'polotno/utils/image';
import { useProject } from '../project';
import { UserContext } from "../contex/user";
const API = 'https://api.polotno.dev/api';

const KEYWORDS = [
  'SAVED TEXTS',

];


const GenerateTab = observer(({ store }) => {
  const project = useProject();
  const inputRef = React.useRef(null);
  const [image, setImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { credits, consumeCredits } = useCredits('stableDiffusionCredits', 10);
  const [state, dispatch] = useContext(UserContext);
  const [query, setQuery] = React.useState('');
  const [keywords, setKeywords] = React.useState('');
  const [items, setItems] = React.useState([]);

  const timeout = React.useRef();
  const requestQuery = (query) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setQuery(query);
    }, 500);
  };

  const filteredData = items?.filter((el) => {
    if (items.length > 0) {
      if (query === "") {
        return el;
      } else {
        return el.text.toLowerCase().includes(query);
      }
    }
  });


  React.useEffect(() => {
    let skipResults = false;

    const run = async () => {
      setLoading(true);

      var str = state.customString.slice(1)

      console.log(str)
      if (str.startsWith("%22")) {
        str = str.substring(3);
      }
      if (str.endsWith("%22")) {
        str = str.substring(0, str.length - 3);
      }

      const reqx = await fetch(
        `https://azureapi.orbina.ai/template/liststext`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${str}`,
          'Content-Type': 'application/json'
        }
      }
      );
      const datax = await reqx.json();

      var temp = []
      datax.map(item => {
        var newItemx = {};
        newItemx["author"] = item.topic;
        newItemx["text"] = item.translation;
        newItemx["id"] = item._id;
        newItemx["keywords"] = item.keyword;

        temp.push(newItemx)
      })

      setLoading(false);

      setItems(temp);
    };
    run();
    return () => {
      skipResults = true;
    };
  }, [ keywords]);

  const moreButton = (
    <a
      onClick={() => {
        project.puterModalVisible = true;
      }}
      href="#"
    >
      Need more?
    </a>
  );

  return (
    <>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <InputGroup
          leftIcon="search"
          placeholder="Search..."
          onChange={(e) => {
            requestQuery(e.target.value);
          }}
          style={{
            marginBottom: '20px',
          }}
        />
        <div>
          {KEYWORDS.map((keyword) => (
            <Button
              key={keyword}
              onClick={() => {
                if (keyword === keywords) {
                  setKeywords('');
                } else {
                  setKeywords(keyword);
                }
              }}
              minimal
              active={keyword === keywords}
              style={{ fontSize: '14px', padding: '2px 8px' }}
            >
              {keyword}
            </Button>
          ))}
        </div>
        {loading && <div style={{ padding: '10px' }}>Loading...</div>}
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            display: loading ? 'none' : 'block',
          }}
        >
          {filteredData.map((item) => (
            <Card
              key={item.id}
              interactive
              style={{ margin: '10px' }}
              onClick={() => {
                const width = 500;
                const textEl = store.activePage.addElement({
                  type: 'text',
                  text: item.text,
                  width: width,
                  x: store.width / 2 - width / 2,
                  y: store.height / 2 - 100,
                  fontFamily: 'Atma',
                  fontSize: 30,
                });
                setTimeout(() => {
                  const authorEl = store.activePage.addElement({
                    type: 'text',
                    text: item.author,
                    y: textEl.y + textEl.height + 10,
                    x: textEl.x,
                    align: 'right',
                    width: width,
                    fontSize: 20,
                    fontFamily: 'Atma',
                  });
                  store.selectElements([textEl.id, authorEl.id]);
                }, 60);
              }}
            >
              <div>{item.text}</div>
              <div style={{ textAlign: 'right' }}>{item.author}</div>
            </Card>
          ))}
          {!loading && filteredData.length === 0 && (
            <div style={{ padding: '10px' }}>No results</div>
          )}
        </div>
      </div>
    </>
  );
});

const RANDOM_QUERIES = [
  'Magic mad scientist, inside cosmic labratory, radiating a glowing aura stuff, loot legends, stylized, digital illustration, video game icon, artstation, ilya kuvshinov, rossdraws',
  'cute duckling sitting in a teacup, photography, minimalistic, 8 k ',
  'anime girl',
  'an mascot robot, smiling, modern robot, round robot, cartoon, flying, fist up, crypto coins background',
];

const SearchTab = observer(({ store }) => {
  // load data
  const [query, setQuery] = React.useState('');
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [state, dispatch] = useContext(UserContext);
  const [delayedQuery, setDelayedQuery] = React.useState(
    RANDOM_QUERIES[(RANDOM_QUERIES.length * Math.random()) | 0]
  );
  console.log("data : ", data)

  const filteredData = data?.filter((el) => {
    if (data.length > 0) {
      if (query === "") {
        return el;
      } else {
        return el.prompt.toLowerCase().includes(query);
      }
    }
  });

  console.log(filteredData)
  const requestTimeout = React.useRef();
  React.useEffect(() => {
    requestTimeout.current = setTimeout(() => {
      setDelayedQuery(query);
    }, 1000);
    return () => {
      clearTimeout(requestTimeout.current);
    };
  }, [query]);

  React.useEffect(() => {
    async function load() {
      setIsLoading(true);
      setError(null);
      try {
        var str = state.customString.slice(1)
        if (str.startsWith("%22")) {
          str = str.substring(3);
        }
        if (str.endsWith("%22")) {
          str = str.substring(0, str.length - 3);
        }

        const reqx = await fetch(
          `https://azureapi.orbina.ai/template/listsimage`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${str}`,
            'Content-Type': 'application/json'
          }
        }
        );
        const datax = await reqx.json();

        var temp = []
        datax.map(item => {
          var newItemx = {};
          newItemx["src"] = item.url;
          newItemx["srcSmall"] = item.url;
          newItemx["gallery"] = item.url;
          newItemx["id"] = item._id;
          newItemx["nsfw"] = false;
          newItemx["prompt"] = item.prompt;
          newItemx["width"] = 1536;
          newItemx["height"] = 1536;
          newItemx["grid"] = true;
          temp.push(newItemx)
        })

        setData(temp);
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    }
    load();
  }, [delayedQuery, state.customString]);

  return (
    <>
      <InputGroup
        leftIcon="search"
        placeholder={t('sidePanel.searchPlaceholder')}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        type="search"
        style={{
          marginBottom: '20px',
        }}
      />

      <ImagesGrid
        shadowEnabled={false}
        images={filteredData}
        getPreview={(item) => item.srcSmall}
        isLoading={isLoading}
        onSelect={async (item, pos, element) => {
          if (element && element.type === 'svg' && element.contentEditable) {
            element.set({ maskSrc: item.src });
            return;
          }

          const { width, height } = await getImageSize(item.srcSmall);

          if (element && element.type === 'image' && element.contentEditable) {
            const crop = getCrop(element, {
              width,
              height,
            });
            element.set({ src: item.src, ...crop });
            return;
          }

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;
          store.activePage?.addElement({
            type: 'image',
            src: item.src,
            width,
            height,
            x,
            y,
          });
        }}
        rowsNumber={2}
      />
    </>
  );
});

const StableDiffusionPanel = observer(({ store }) => {
  const [selectedTabId, setSelectedTabId] = React.useState('search');
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs
        id="TabsExample"
        defaultSelectedTabId="search"
        onChange={(tabId) => {
          setSelectedTabId(tabId);
        }}
      >
        <Tab id="search" title="Images" />
        <Tab id="generate" title="Texts" />
      </Tabs>
      <div
        style={{
          height: 'calc(100% - 20px)',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
        }}
      >
        {selectedTabId === 'search' && <SearchTab store={store} />}
        {selectedTabId === 'generate' && <GenerateTab store={store} />}
      </div>
    </div>
  );
});

// define the new custom section
export const StableDiffusionSection = {
  name: 'stable-diffusion',
  Tab: (props) => (

    <SectionTab name="AI Generated" {...props}>
      <FaBrain />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: StableDiffusionPanel,
};
